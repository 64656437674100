require('./css/main.scss')

require('./js/hamburger-menu')
require('./js/more-details')
require('./js/email')

require('./componentes/Project/Project')
require('./componentes/Project/ProjectArchersDeCaen')
require('./componentes/Project/ProjectGanapati')
require('./componentes/Project/ProjectDigiformaSync')
require('./componentes/Project/ProjectKanta')

require('./componentes/Skill')
